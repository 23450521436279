import { showAlertMsg, loadingData } from "./CommonMethod"
export const ENDPOINT = process.env.REACT_APP_ENDPOINT
export const ASSET_ENDPOINT = process.env.REACT_APP_ASSET_ENDPOINT

export const LOGIN = "/login"
export const ADMIN_SIGNUP = "/admin-sign-up"
export const FORGOT_PASSWORD = "/forgot-password"
export const RESET_PASSWORD = "/reset-password"
export const LOGOUT = "/logout"
export const CHANGE_PASSWORD = "/change-password"
export const VERIFY_EMAIL = "/verify-email"
export const VERIFY_LOGIN_TOKEN = '/verify-login-token'

export const GET_AUTH_USER_INFO = "/get-auth-user"
export const UPDATE_ADMIN_PROFILE = "/update-admin-profile"

/** Manage Groups */
export const ASSIGN_GROUP_WORKBOOK = "/assign-workbook"
export const GET_GROUP_WORKBOOKS = "/get-assigned-workbooks"
export const UNASSIGN_GROUP_WORKBOOK = "/unassign-workbook"

/** Manage Web Content */
export const GET_WEBSITE_CONTENT = "/get-website-content";
export const UPSERT_HOME_CONTENT = "/upsert-home-content";
export const UPSERT_ABOUT_CONTENT = "/upsert-about-content";
export const UPSERT_SOSIAL_LINK_CONTENT = "/upsert-social-link-content";
export const UPSERT_CHECKOUT_CONTENT = "/upsert-checkout-content";
export const UPSERT_SHOPT_CONTENT = "/upsert-shop-content";
export const UPSERT_WORKBOOK_CONTENT = "/upsert-workbook-content";

export const ADD_UPDATE_TERMS_CONDITIONS = "/add-terms-content"
export const SEND_SINGLE_INVITE = "/add-group-student"
export const SEND_BULK_INVITE = "/upload-group-student"
export const SETTING_PAGE = "/setting-page"
export const PAGE_CONTENT = "/page-content"
export const DELETE_SETTING_IMAGE = "/delete-setting-image"

/**Manage Shop Listing*/

export const FETCH_LISTINGS = "/get-all-listing"
export const GET_SPECIFIC_LISTING = "/get-listing"


export const GET_LEARNING_OUTCOMES = "/get-learning-outcomes"
export const COURSE_SHOP_LISTING = "/course-shop-listing"
export const COURSE_SHOP_LISTING_FILTER = "/course-shop-listing-filter"
export const BEST_SHOP_LISTING = "/best-seller-listing"
export const GET_SIMILAR_LISTING = "/get-similar-listing"

// Course
export const COURSE_LIST = "/course-list"
export const COURSE_CREATE = "/course-create"
export const COURSE_UPDATE = "/course-update"
export const COURSE_DELETE = "/course-delete"

// Topic
export const TOPIC_LIST = "/topic-list"
export const TOPIC_CREATE = "/topic-create"
export const TOPIC_UPDATE = "/topic-update"
export const TOPIC_DELETE = "/topic-delete"

// Section
export const SECTION_LIST = "/section-list"
export const SECTION_CREATE = "/section-create"
export const SECTION_UPDATE = "/section-update"
export const SECTION_DELETE = "/section-delete"

// Group
export const GROUP_LIST = "/group-list"
export const GROUP_CREATE = "/group-create"
export const GROUP_UPDATE = "/group-update"
export const GROUP_DELETE = "/group-delete"
export const GROUP_STATUS_CHANGE = "/group-status-change"

// Group Section
export const GROUP_SECTION_LIST = "/group-section-list"
export const GROUP_SECTION_CREATE = "/group-section-create"
export const GROUP_SECTION_UPDATE = "/group-section-update"
export const GROUP_SECTION_STATUS_CHANGE = "/group-section-status-change"

// Workbooks
export const WORKBOOK_LIST = "/workbook-list"
export const WORKBOOK_CREATE = "/workbook-create"
export const WORKBOOK_DELETE = "/remove-workbooks"
export const WORKBOOK_UPDATE = "/workbook-update"
export const GET_SINGLE_WORKBOOK = "/get-single-workbook"
export const DUPLICATE_WORKBOOK = "/duplicate-workbook"
export const GET_LAST_WORKBOOK_ID = "/get-last-workbook-id"

// Question
export const QUESTIONS_LIST = "/get-questions"
export const QUESTION_CREATE = "/create-question"
export const QUESTION_DELETE = "/delete-question"
export const QUESTION_DUPLICATE = "/duplicate-question"
export const QUESTION_DRAGGABLE_IMAGE = "/upload-question-draggble-image"

// Listings
export const LISTING_LIST = "/get-shop-listing"
export const LISTING_CREATE = "/create-listing"
export const LISTING_UPDATE = "/update-listing"
export const LISTING_DUPLICATE = "/duplicate-unpublish-listing"
export const LISTING_STATUS_UPDATE = "/publish-listing"
export const LISTING_REMOVE = "/remove-listing"

// User
export const USER_LIST = "/user-list"
export const USER_CREATE = "/sign-up"
export const USER_STATUS_CHANGE = "/user-status-change"
export const USER_DELETE = "/user-delete"
export const GET_UPDATE_STUDENT_DETAILS = "/get-update-student-details"
export const USER_WORKBOOK_UNASSIGN = "/student-workbook-unassign"
export const USER_WORKBOOK_ASSIGN = "/student-workbook-assign"
export const USER_WORKBOOK_LIST = "/student-workbook-list"
export const SET_PERMISSION = "/set-permission"
export const CREATE_TEACHER = "/create-teacher"
export const UPDATE_TEACHER = "/update-teacher"
export const DELETE_TEACHER = "/delete-teacher"
export const EMAIL_VERIFY = "/user-email-verification"
export const DIRECT_LOGIN = "/direct-login"

// Admin
export const USER_ASSIGN_GROUP = "/assign-group"
export const USER_ASSIGN_GROUP_SECTION = "/assign-group-section"
export const UPDATE_STUDENT_INFO = "/update-student-info"
export const UPDATE_PARTIAL_ADMIN = "/update-partial-admin"
export const UPDATE_GUARDAIAN_DETAILS = "/update-guardian-details"
export const GET_TOKEN_VALUE = "/get-token-value"

// Common Routes
export const DROPDOWN_DATA = "/dropdown-data"

// Cart Routes
export const ADD_TO_CART = "/add-to-cart"
export const REMOVE_FROM_CART = "/remove-from-cart"
export const CART_ITEM = '/get-cart-item'
export const GET_ORDER_DETAILS = '/get-order-details'
export const CART_LIST = '/get-carts'
export const GUEST_CART_LIST = '/get-guest-carts'
export const UPSERT_BILLING_DETAILS = '/order-to-cart-item'

// Payment Routes
export const PAYMENT = '/payment'
export const STRIPE_CHECKOUT = "/stripe-checkout"
export const STRIPE_PAYMENT_VERIFY = "/stripe-payment-verify"
export const PAYMENT_DETAILS = "/payment-details"

// My Workbook Routes
export const MY_WORKBOOKS_FILTER_ITEM = '/my-workbooks-filter-item'
export const MY_WORKBOOKS = '/my-workbooks'
export const DOWNLOAD_WORKBOOK_NOTES = '/download-workbook-notes'

// Enquiry Section
export const ENQUIRY_LIST = "/enquiry-list"
export const ADD_ENQUIRY = "/add-enquiry"
export const ENQUIRY_UPDATE = "/enquiry-update"
export const ENQUIRY_DELETE = "/remove-enquiry"
export const STATUS_ENQUIRY_UPDATE = "/status-enquiry-update"

// Orders
export const SALE_SHORT_LIST = "/get_top_sales"
export const ORDER_SHORT_LIST = "/get_new_orders"
export const GET_SALES_AND_BOUNCE_RATE = "/get_sales_and_bounce_rate"
export const USER_LIST_GRAPH = "/user_list_graph"
export const GET_ORDER_WEEK_MONTH_YEAR = "/get_order_week_month_year_wise"

// Exam
export const EXAM_INFO = "/get-exam-info"
export const SET_EXAM_RECORD = "/set-exam-record"
export const SUBMIT_ANSWER = "/submit-answer"
export const RESTART_EXAM = "/restart-exam"
export const QUIT_EXAM = "/quit-exam"
export const END_EXAM = "/end-exam"
export const REVIEW_SUBMIT = "/submit-performance"

//Dashboard Chart Section
export const BALANCE_OVERVIEW = "/balance_overview"
export const USER_COUNT = "/count"

// Assignment
export const ASSIGNMENT_LIST = "/assignment-list"
export const ASSIGNMENT_CREATE = "/assignment-create"
export const ASSIGNMENT_DELETE = "/remove-assignment"
export const ASSIGNMENT_UPDATE = "/assignment-update"
export const GET_SINGLE_ASSIGNMENT = "/get-single-assignment"
export const ASSIGNMENT_DUPLICATE = "/duplicate-assignment"
export const ASSIGNMENT_STATUS_CHANGE = "/assignment-status-update"
export const ASSIGNMENT_GROUP_SECTION = "/assign-group-section-to-assignment"
export const ASSIGNMENT_WORKBOOK = "/assign-workbook-to-assignment"
export const MY_ASSIGNMENT = "/my-assignments"
export const MY_ASSIGNMENT_WORKBOOKS = "/my-assignment-workbooks"
export const ASSIGNMENT_ANALYTICS = "/assignment-analytics"
export const USERS_ANALYTICS = "/users-analytics"

//Downloads Analytics
export const USERS_ANALYTICS_PDF = "/users-analytics-pdf"
export const ASSIGNMENTS_ANALYTICS_PDF = "/assignment-analytics-pdf"
export const GROUP_SELECTION = "/group-selection"
export const WORKBOOK_ANALYTICS = "/workbook-analytics"
export const REVIEW_DETAILS = "/review-details"
export const GET_WORKBOOK_ANALYTIC_FILTER = "/get-workbook-analytic"

//Settings
export const GET_SETTINGS = '/get-settings'
export const UPDATE_SETTINGS = '/update-settings'

//Reviews
export const GET_AVERAGE_WORKBOOK_ANALYTIC = '/get-average-workbook-analytic'
export const GET_AVERAGE_CLASS_ANALYTIC = '/get-average-class-analytic'
export const GET_AVERAGE_STUDENT_ANALYTIC = '/get-average-student-analytic'
export const GET_AVERAGE_MODULE_ANALYTIC = '/get-average-module-analytic'
export const GET_AVERAGE_INDIVIDUAL_STUDENT_ANALYTIC = '/get-individual-student-analytic'
export const GET_AVERAGE_INDIVIDUAL_STUDENT_WORKBOOK_ANALYTIC = '/get-individual-student-workbook-analytic'
export const GET_AVERAGE_QUESTION_ANALYTIC = '/get-average-questions-analytic'

export const fetchData = async (url, method, data, token, process, res, abort_signal = false, process_type = false, form_id = '', loading = true) => {
    if (loading) loadingData(true)
    let headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

    if (process) {
        headers = { ...headers, 'contentType': false, 'processData': false }
    } else {
        headers = { ...headers, 'Content-Type': 'application/json' }
    }

    if (token) {
        let TOKEN = localStorage.getItem('waae-token')
        headers = { ...headers, 'Authorization': 'Bearer ' + TOKEN }
    }

    let request = {
        'method': method,
        'headers': headers,
    }

    if (abort_signal) {
        request = { ...request, 'signal': abort_signal }
    }

    if (data) {
        request = { ...request, 'body': process ? data : JSON.stringify(data) }
    }

    await fetch(`${ENDPOINT}${url}`, request).then((response) => process_type === "text" ? response.text() : (process_type === "blob" ? response.blob() : response.json())).then((json) => {
        if (loading) loadingData()
        if (json.message === "Unauthenticated." || json.type === "block_user") {
            if (json.type === "block_user") localStorage.setItem("block_user", JSON.stringify(json));
            localStorage.removeItem("waae-token");
            localStorage.removeItem('waae-admin-token')
            window.location.href = '/'
        } else if (!json.records) {
            showAlertMsg(json, form_id)
            res(json)
        } else if (json.records && json.records.length > 0) {
            res(json)
        } else {
            showAlertMsg(json, form_id)
            res(json)
        }
    }).catch((error) => { console.log(error) });
}
